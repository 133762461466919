<template>
  <div v-if="activity">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">Editing Activity: <small>{{ activityCount }}</small></h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors"/>

    <div class="form-group text-left">
      <label for="activity_title">Activity Title</label>
      <input type="text" class="form-control" id="activity_title" v-model="activity.title" placeholder="Title">
    </div>

    <div v-if="!activity.is_archived" class="form-group text-left d-block">
      <b-tooltip target="report_order_tooltip" placement="left" triggers="hover">
        Column number of the field in the Activity Report Summary.
      </b-tooltip>
      <label for="market_area_id">Report Column Number</label>
      <font-awesome-icon id="report_order_tooltip" icon="question-circle" size="md" class="ml-1" />
      <br/>
      <div class="d-inline-block">
        <select class="form-control" v-model="activity.report_order" id="activity_report_order">
          <option v-for="i in activityCount + 1" :value="i" v-bind:key="i.toString()" >{{ i.toString() }}</option>
        </select>
      </div>
    </div>

    <div class="form-group text-left">
      <label for="activity-impediment">Enable Trainees?</label>
      <b-checkbox id="activity-coach-impediment" v-model="activity.coach_impediment"/>
    </div>

    <div class="form-group text-left">
      <label for="activity-impediment">Enable Audiences?</label>
      <b-checkbox id="activity-topic-impediment" v-model="activity.topic_impediment"/>
    </div>

    <div class="form-group text-left">
      <label for="activity-impediment">Include this activity in reports?</label>
      <b-checkbox id="activity-impediment" v-model="activity.include_in_reports"/>
    </div>

    <div class="form-group text-left">
      <label for="activity-archived">Is Archived?</label>
      <b-checkbox id="activity-archived" v-model="activity.is_archived"/>
    </div>

    <div class="footer float-right">
      <div class="btn-group">
        <router-link :to="{ name: 'ActivityShow', params: { id: this.$route.params.id } }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
        <button v-on:click="updateActivity" type="button" class="btn btn-success">Update Activity</button>
      </div>
    </div>

    <br>

  </div>
  <loading-overlay v-else/>
</template>
<script>
import { ActivityService } from '@/common/services/activity.service';
import DangerAlert from '@/common/components/danger-alert.vue';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'LanguageEdit',
  components: {
    DangerAlert,
    LoadingOverlay,
    FontAwesomeIcon,
  },
  data() {
    return {
      activity: null,
      errors: [],
      activityCount: 0,
    };
  },
  created() {
    ActivityService.get(this.$route.params.id)
      .then(({ data }) => {
        this.activity = data;
      });
    this.activityCount = this.$route.params.activityCount || 20;
  },
  methods: {
    updateActivity() {
      ActivityService.update(this.$route.params.id, this.activity)
        .then(() => {
          this.$router.push({ name: 'ActivityShow', params: { id: this.$route.params.id } });
        }).catch((err) => {
          // Scroll to the top of this view so user's can see errors
          window.scrollTo(0, document.getElementById('top').offsetTop);
          this.errors = err.response.data.error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/activities";
</style>
